<template>
    <div class="impressionen" v-if="content">
        <h3 class="title is-3">{{title}}</h3>
        <b-carousel-list v-model="test" :data="data" :items-to-show="items_shown">
            <template #item="list">
                <div class="card">
                    <iframe v-if="String(list.value).includes('yout')" width="560" height="315" :src="`${list.value}?controls=0`"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                    <div class="card-image" v-else>
                        <img :src="list.value">
                    </div>

                </div>
            </template>
        </b-carousel-list>
    </div>
</template>
<style>
.impressionen {
    margin-bottom: 20px;
}
</style>
<script>
    export default {
        props: {
            title: {
                type: String,
                required: true
            },
            content: {
                type: Array,
                required: true,
                default: []
            }
        },
        data() {
            return {
                test: 0,
                data: null
            }
        },
        created() {
            this.data = this.content.map(element => {
                return {
                    value: element
                }
            })
        },
        computed: {
            items_shown() {
                return this.$isMobile() ? 1.5 : 2.2
            },
            items() {
                return this.content
            }
        }
    }
</script>